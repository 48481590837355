/* eslint-disable camelcase */
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import BackButton from '../components/common/BackButton'
import PostsGrid from '../components/blog/PostsGrid'

const Wrapper = styled.div`
  position: relative;
  padding-top: 80px;
  min-height: 100vh;

  @media (max-width: 1050px) {
    padding-top: 56px;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  top: 0;
  margin-left: -300%;
  height: 100%;
  z-index: -1;
  background-color: #fafafa;

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Posts = ({ data: { allPrismicPost } }) => {
  if (!allPrismicPost) return null

  return (
    <Layout customSEO showHeader>
      <SEO
        title="All Posts | AudioEye"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        desc="Check out the latest stories about digital accessibility, WCAG / ADA website compliance, AudioEye, and more."
      />
      <Wrapper>
        <BackButton text="Back to blog" to="/blog/" />
        <PostsGrid allPostsData={allPrismicPost} pagination categoryPage="all" />
        <BG className="bg" />
      </Wrapper>
    </Layout>
  )
}

export default Posts

export const query = graphql`
  query AllPostsQuery($limit: Int!, $skip: Int!) {
    allPrismicPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: {
        data: {
          post_type: { eq: "Blog" }
          publish_date: { ne: null }
          hide_from_feeds: { eq: false }
          password: { eq: null }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
      totalCount
      edges {
        node {
          uid
          data {
            description
            publish_date
            hide_from_feeds
            password
            title {
              text
            }
            banner {
              alt
              url
              gatsbyImageData(width: 540)
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            tags {
              tag {
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
